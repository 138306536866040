.PatientRiskNote{
    width: 840px;
padding: 38px 0px 60px 0px;

background: #FFFFFF;
border: 1px solid rgba(102, 181, 195, 0.5);
box-sizing: border-box;
box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
border-radius: 30px;
}

.patientriskheading{
    font-family: 'GreycliffCFBold';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #046673;
}

.patientRiskNotesDropdown{
    width: 346px;
    height: 58px;
    background: rgba(209, 232, 237, 0.3);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}

.patientRiskNotesDropdownText{
    width: 168px;
    height: 18px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #013C44;
    margin-bottom: 10px;
}

.patientRiskSelectField{
    margin-top: 20px;
    width: 346px;
    max-height: 360px;
    overflow: auto;
    background: rgba(209, 232, 237, 0.3);
    opacity: 0.6;
    border-radius: 20px;
    padding: 10px 29px;
}

.checkboxContainer{
    width: 287px;
    margin-bottom: 15px;
    .MuiFormControlLabel-label{
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 50px;
        color: #013C44;
        margin-left: 20px;
    }
}

.checkboxContainerBodder{
    border-bottom: 0.5px solid #66B5C3;
}

.patientRishNotsInputfield{
    width: 346px;
    height: 107px;
    background: rgba(209, 232, 237, 0.3);
    border-radius: 20px;
    resize: none;
    outline:none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    border: none;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 20px;
}

.patientRishNotsInputfield::placeholder{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #013C44;
    opacity: 0.5;
}

.patientRiskNotesButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 323px;
    height: 63px;
    background: #04A9C8;
    border-radius: 20px;
}

.patientRiskNotesButtonTxt{
    width: 245px;
    height: 21px;
    font-family: 'GreycliffCFBold';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.actiontakenButton{
    width: 346px;
    height: 150px;
    background: rgba(209, 232, 237, 0.3);
    border-radius: 20px;
    margin-bottom: 20px;
}

.patientRiskNotesradioCustom{
    width: 18px;
    height: 18px;
    border: 1px solid #00839B;
    border-radius: 50%;
}

.patientRiskNotesradioCustom .checkmark{
width: 10px;
height: 10px;
background-color: #00839B;
display: none;
}
.radiobuttonlabel{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 50px;
    color: #013C44;
    margin-left: 20px;
}


.radiocontainer{
    display: flex;
    align-items: center;
    cursor: pointer;
}