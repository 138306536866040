.PsychiatryCaseNotes{
    width: 840px;
height: 5100px;

background: #D9EDF1;
border: 1px solid rgba(55, 129, 152, 0.3);
box-sizing: border-box;
border-radius: 30px;
}

.psycheading{
    font-family: 'GreycliffCFBold';
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
/* identical to box height */
color: #013C44;
margin-bottom: 8px;
}

.psycdivider{
    width: 837px;
height: 0px;

background: #FFFFFF;
border: 0.5px solid #00839B;
}

.psycinputs{
    width: 728px;
    height: 122px;
    font-family: "VisueltRegular";
    color: #046673;
    background: #FFFFFF;
    padding: 20px 10px;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    margin-bottom: 10px;
    resize: none;
}

.secondarylabel{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 26px;
display: flex;
align-items: center;
margin-bottom: 8px;
color: #013C44;
}

.button{
    display: flex;
    float: right;
    width: 205.26px;
height: 50px;
cursor: pointer;
background: #00839B;
border-radius: 10px;
}

.buttonTxt{
    font-family: 'GreycliffCFBold';
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 18px;
text-align: center;

color: #FFFFFF;
}