.content-image {
    //width: 288px;
    height: 323.92px;
    margin-bottom: 25px;
    .profileimg {
        width: 100px;
        height: 317.92px;
        border: 1px solid #7ebdc9;
        margin: 6px 26px 0px 7px;
        border-radius: 15px;
        object-fit: cover;
    }
}
.detailcontent {
    padding-top: 24px;
    padding-bottom: 10px;
    .content {
        //width: 375px;
        position: relative;
        .name {
            // font-family: Greycliff CF;
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 35px;
            letter-spacing: -0.3px;
            color: #013c44;
        }
        .special {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 32px;
            letter-spacing: -0.3px;
            color: #04a9c8;
        }
        .star {
            width: 78.45px;
            height: 35px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: #ffffff;
            border: 1px solid rgba(102, 195, 189, 0.5);
            box-sizing: border-box;
            box-shadow: -1px 6px 8px 1px rgba(8, 180, 212, 0.15);
            border-radius: 10px;
            position: absolute;
            top: 5px;
            right: 0px;
            font-family: "GreycliffCFBold";
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 23px;
            letter-spacing: -0.3px;
            color: #00839b;
        }
    }
    .contentone {
        display: flex;
        margin-top: 37px;
        .separater {
            width: 33%;
            .name {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 22px;
                color: rgba(4, 102, 115, 0.7);
            }
            .value {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #046673;
            }
        }
    }
    .contenttwo {
        display: flex;
        margin-top: 27px;
        .separater {
            .name {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 22px;
                color: rgba(4, 102, 115, 0.7);
            }
            .value {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #046673;
            }
        }
    }
    .contentthree {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 34px;
        .images {
            display: flex;
            margin-right: 50px;
            height: 27px;
            .image {
                width: 26.57px;
                height: 26.57px;
                background: #ffffff;
                border: 1px solid rgba(102, 195, 189, 0.5);
                box-sizing: border-box;
                margin-right: 6px;
                box-shadow: -1px 7px 15px -1px rgba(8, 180, 212, 0.15);
                border-radius: 30px;
                display: flex;
                justify-content: CENTER;
                align-items: CENTER;
                cursor: pointer;
            }
            .disabled_image{
                width: 26.57px;
                height: 26.57px;
                background: #ffffff;
                border: 1px solid rgba(102, 195, 189, 0.5);
                box-sizing: border-box;
                margin-right: 6px;
                box-shadow: -1px 7px 15px -1px rgba(8, 180, 212, 0.15);
                border-radius: 30px;
                display: flex;
                justify-content: CENTER;
                align-items: CENTER;
                cursor: pointer;
                pointer-events: none;
                opacity: 0.4;
            }
        }
        .btn {
            // width: 219px;

            height: 40px;
            background: #08b4d5;
            border-radius: 10.2204px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0px 15px;
            cursor: pointer;
            max-width: 220px;
            .btnimg {
                margin-right: 5px;
            }
            .btntxt {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #ffffff;
            }
        }
        .disabled_btn{
            height: 40px;
            background: #08b4d5;
            border-radius: 10.2204px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0px 15px;
            max-width: 220px;
            pointer-events: none;
                opacity: 0.4;

            .btnimg {
                margin-right: 5px;
            }
            .btntxt {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #ffffff;
            }
        }
    }
}
.profile-container {
    width: 729px;
    height: 650;
    background: #ffffff;
    border: 1px solid rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
    border-radius: 30px;
    margin-right: 30;
    margin-top: 21px;
    .content-one {
        height: auto;
        border-bottom: 1px solid #4b909c;
        margin: 20px;
        margin-bottom: 28px;
        display: flex;
        .content-image {
            //width: 288px;
            height: 323.92px;
            margin-bottom: 25px;
            .profileimg {
                // width: 100px;
                width: 262px;
                height: 317.92px;
                border: 1px solid #7ebdc9;
                margin: 6px 26px 0px 0px;
                border-radius: 15px;
                object-fit: cover;
            }
        }
        .detailcontent {
            padding-top: 24px;
            padding-bottom: 10px;
            .content {
                //width: 375px;
                position: relative;
                .name {
                    // font-family: Greycliff CF;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 35px;
                    letter-spacing: -0.3px;
                    color: #013c44;
                }
                .special {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 32px;
                    letter-spacing: -0.3px;
                    color: #04a9c8;
                }
                .star {
                    width: 78.45px;
                    height: 35px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    background: #ffffff;
                    border: 1px solid rgba(102, 195, 189, 0.5);
                    box-sizing: border-box;
                    box-shadow: -1px 6px 8px 1px rgba(8, 180, 212, 0.15);
                    border-radius: 10px;
                    position: absolute;
                    top: 5px;
                    right: 0px;
                    font-family: "GreycliffCFBold";
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 23px;
                    letter-spacing: -0.3px;
                    color: #00839b;
                }
            }
            .contentone {
                display: flex;
                margin-top: 37px;
                .separater {
                    width: 33%;
                    .name {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 22px;
                        color: rgba(4, 102, 115, 0.7);
                    }
                    .value {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: #046673;
                    }
                }
            }
            .contenttwo {
                display: flex;
                margin-top: 27px;
                .separater {
                    .name {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 22px;
                        color: rgba(4, 102, 115, 0.7);
                    }
                    .value {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 22px;
                        color: #046673;
                    }
                }
            }
            .contentthree {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 34px;
                .images {
                    display: flex;
                    margin-right: 50px;
                    height: 27px;
                    .image {
                        width: 26.57px;
                        height: 26.57px;
                        background: #ffffff;
                        border: 1px solid rgba(102, 195, 189, 0.5);
                        box-sizing: border-box;
                        margin-right: 6px;
                        box-shadow: -1px 7px 15px -1px rgba(8, 180, 212, 0.15);
                        border-radius: 30px;
                        display: flex;
                        justify-content: CENTER;
                        align-items: CENTER;
                        cursor: pointer;
                    }
                    .disabled_image{
                        width: 26.57px;
                        height: 26.57px;
                        background: #ffffff;
                        border: 1px solid rgba(102, 195, 189, 0.5);
                        box-sizing: border-box;
                        margin-right: 6px;
                        box-shadow: -1px 7px 15px -1px rgba(8, 180, 212, 0.15);
                        border-radius: 30px;
                        display: flex;
                        justify-content: CENTER;
                        align-items: CENTER;
                        cursor: pointer;
                        pointer-events: none;
                        opacity: 0.4;
                    }
                }
                .btn {
                    // width: 219px;

                    height: 40px;
                    background: #08b4d5;
                    border-radius: 10.2204px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0px 15px;
                    cursor: pointer;
                    max-width: 220px;
                    .btnimg {
                        margin-right: 5px;
                    }
                    .btntxt {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 17px;
                        color: #ffffff;
                    }
                }
                .disabled_btn{
                    height: 40px;
                    background: #08b4d5;
                    border-radius: 10.2204px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0px 15px;
                    max-width: 220px;
                    pointer-events: none;
                        opacity: 0.4;

                    .btnimg {
                        margin-right: 5px;
                    }
                    .btntxt {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 17px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .content-two {
        display: flex;
        .detail-content {
            width: 311px;
            // height: 254px;
            margin-left: 29px;
            margin-right: 25px;
            // background: #f1f9f5;
            border-radius: 20px;
            padding: 34px 27px 2px 26px;
            .detail {
                display: flex;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-bottom: 11px;
                margin-bottom: 10px;
                // border-bottom: 1px solid rgba(0, 131, 155, 0.5);
                .title {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26.08px;
                    text-align: center;
                    color: #013c44;
                }
                .value {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26.08px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #013c44;
                }
            }
        }
        .price-content {
            .price {
                //width: 334px;
                height: 111px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #d8ede3;
                border-radius: 20px;
                .title {
                    font-family: "GreycliffCFBold";
                    font-style: normal;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 35px;
                    text-align: center;
                    letter-spacing: -0.3px;

                    color: #013c44;
                }
                .value {
                    font-family: "VisueltMedium";
                    font-style: normal;
                    font-weight: 900;
                    font-size: 45px;
                    line-height: 52px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.3px;

                    color: #013c44;
                }
            }
            .price-detail {
                display: flex;
                justify-content: space-between;
                margin-top: 21px;
                margin-bottom: 20px;
                .one {
                    width: 152.58px;
                    height: 38px;

                    background: #ffffff;
                    border: 1px solid rgba(102, 195, 189, 0.5);
                    box-sizing: border-box;
                    box-shadow: -1px 6px 8px 1px rgba(8, 180, 212, 0.15);
                    border-radius: 10px;
                    font-family: "GreycliffCFBold";
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 116.1%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.3px;

                    color: #00839b;
                }
                .two {
                    min-width: 152.58px;
                    height: 38px;
                    max-width: 100%;
                    background: #ffffff;
                    border: 1px solid rgba(102, 195, 189, 0.5);
                    box-sizing: border-box;
                    box-shadow: -1px 6px 8px 1px rgba(8, 180, 212, 0.15);
                    border-radius: 10px;
                    font-family: "GreycliffCFBold";
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 116.1%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    letter-spacing: -0.3px;

                    color: #00839b;
                }
            }
            .price-description {
                width: 90%;
                // height: 63px;
                margin-left: 4%;
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 116.1%;
                /* or 20px */

                letter-spacing: -0.3px;

                color: rgba(4, 102, 115, 0.7);
                a {
                    color: #04a9c8;
                }
            }
        }
    }
}
.rightcontainer {
    display: flex;
    flex-direction: column;
    margin-top: 21px;
}
.calenderContainerProfile {
    //min-width: 558px;
    height: auto;
    background: #ffffff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    .container-border {
        background: #ffffff;
        border: 1px solid rgba(102, 195, 189, 0.5);
        box-sizing: border-box;
        box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
        border-radius: 30px;
        height: 100%;
        .icon {
        }
        .header-border {
            margin-bottom: 20px;
        }
        .column-days {
            display: flex;
            justify-content: center;
            align-items: center;
            .disabled {
                line-height: 24px !important;
            }
            .number {
                line-height: 24px !important;
            }
        }
    }
}
.btncollection {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .holder {
        display: flex;
        margin-bottom: 20px;
        .btn {
            //width: 280.76px;
            height: 88px;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            background: #ffffff;
            border: 1px solid rgba(102, 195, 189, 0.5);
            box-sizing: border-box;
            box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
            border-radius: 20px;
            min-width: 50%;
            .imageholder {
                width: 39.61px;
                height: 45px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 19px;
            }
            .textholder {
                .title {
                    font-family: "GreycliffCFBold";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 17px;
                    letter-spacing: -0.3px;
                    color: rgba(1, 60, 68, 0.5);
                }
                .value {
                    display: flex;
                    align-items: center;
                    .one {
                        font-family: "VisueltMedium";
                        font-style: normal;
                        font-weight: 900;
                        font-size: 20px;
                        line-height: 23px;
                        letter-spacing: 0.7px;
                        margin-right: 15px;
                        color: #00839b;
                    }
                    .two {
                        font-family: "VisueltMedium";
                        font-style: normal;
                        font-weight: 900;
                        font-size: 20px;
                        line-height: 23px;
                        letter-spacing: 1.7px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .green {
                        color: #37cc8c;
                    }
                    .red {
                        color: #ff6e4e;
                    }
                }
            }
        }
        .marginright {
            margin-right: 18.48px;
        }
    }
}
.about {
    //width: 729px;
    height: 227px;
    margin-right: 30px;
    margin-top: 30px;
    background: #f1f9f5;
    border: 1px solid rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
    border-radius: 30px;
    padding: 18px 40px;
    .title {
        font-family: "GreycliffCFBold";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.3px;

        color: rgba(4, 102, 115, 0.7);
        margin-bottom: 13px;
    }
    .value {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: -0.3px;

        color: #013c44;
    }
}
.income {
    //width: 580px;
    height: 227px;
    margin-top: 30px;
    background: #08b4d4;
    border: 1px solid rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
    border-radius: 30px;
    padding: 21px 29px;
    display: flex;
    align-items: center;
    .image {
        width: 175px;
        height: 186px;

        background: #0696b0;
        border: 1px solid rgba(102, 195, 189, 0.5);
        box-sizing: border-box;
        box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 10px 0px;
        margin-left: 52px;
        .separate {
            width: 294px;
            .title {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 25px;
                line-height: 37px;
                display: flex;
                align-items: center;

                color: #ffffff;
            }
            .value {
                font-family: "VisueltMedium";
                font-style: normal;
                font-weight: 900;
                font-size: 35px;
                line-height: 23px;

                color: #ffffff;
            }
        }
    }
}
.slottime {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    position: absolute;
    left: 40px;
    top: 12px;
    color: #ffffff;
}
@media (min-width: 1600px) {
    .profile-container {
        width: 54.42%;
        margin-right: 30px;
    }
    .rightcontainer {
        width: 42.92%;
    }
    .btncollection {
        .holder {
            justify-content: center;
            .btn {
                min-width: 48%;
            }
        }
    }
    .income {
        width: 42.92% !important;
    }
    .about {
        width: 54.42%;
        margin-right: 30px;
    }
}
@media (min-width: 1280px) and (max-width: 1599px) {
    .profile-container {
        width: 54%;
        margin-right: 25px;
        height: 650;
        .content-two {
            flex-direction: column;
            .detail-content {
                width: auto;
            }
            .price-content {
                margin-left: 29px;
                margin-right: 25px;
                margin-top: 25px;
                margin-bottom: 25px;
            }
        }
    }
    .rightcontainer {
        width: 42.92%;
    }
    .btncollection {
        .holder {
            justify-content: center;
        }
    }
    .income {
        width: 42.92% !important;
        .image {
            img {
                height: 52%;
                padding: 15px;
            }
        }
    }
    .about {
        width: 54%;
        margin-right: 30px;
    }
}
@media (min-width: 1280px) and (max-width: 1420px) {
    .profile-container {
        .content-one {
            flex-direction: column;
            height: auto;
            .content-image {
                .profileimg {
                    width: 90%;
                    // object-fit: contain;
                }
            }
            .detailcontent {
                margin-bottom: 30px;
            }
        }
        .content-two {
            .detail-content {
                .detail {
                    .value {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}
@media (min-width: 300px) and (max-width: 1279px) {
    .profile-container {
        width: 100%;
        //margin-right: 25px;
        height: auto;
        .content-two {
            flex-direction: column;
            .detail-content {
                width: auto;
                .detail {
                    .value {
                        font-size: 18px !important;
                    }
                }
            }
            .price-content {
                margin-left: 29px;
                margin-right: 25px;
                margin-top: 25px;
                margin-bottom: 25px;
                .price-description {
                    width: auto;
                }
            }
        }
    }
    .rightcontainer {
        width: 100%;
        .btncollection {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .holder {
                justify-content: center;
            }
        }
    }

    .income {
        width: 100% !important;
        margin-right: 0px;
        .image {
            img {
                height: 52%;
                padding: 15px;
            }
        }
        
    }
    .about {
        width: 100%;
        margin-right: 0px;
    }
}
.Sidemenu {
    height: 100vh;
}
