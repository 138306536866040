.Referral {
  width: 810px;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(102, 181, 195, 0.5);
  box-sizing: border-box;
  box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
  border-radius: 30px;
  padding: 5% 7% 7% 7%;

  .heading {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    color: #046673;
  }

  .dropdown {
    font-family: "VisueltRefular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #013c44;
    width: 699px;
    height: 58px;
    background: rgba(209, 232, 237, 0.3);
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
    img {
      cursor: pointer;
    }
  }
  .drop_option {
    width: 699px;
    height: 217px;
    overflow: auto;
    background: rgba(209, 232, 237, 0.3);
    opacity: 0.6;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 15px 25px;

    .options {
      font-family: "VisueltRegular";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      color: #013c44;
    }

    .option_border:not(:last-child) {
      border-bottom: 0.5px solid #66b5c3;
    }
  }
  .secondary_heading {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #013c44;
    margin-bottom: 20px;
  }
  .input_referral {
    width: 699px;
    height: 252px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #013c44;
    opacity: 0.5;
    background: rgba(209, 232, 237, 0.3);
    border-radius: 20px;
    border: none;
    outline: none;
    resize: none;
    padding: 15px 25px;
    margin-bottom: 25px;
  }
  .reff_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 323px;
    height: 63px;
    background: #04a9c8;
    border-radius: 20px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}

.refferalSuccPopup {
  width: 522px;
  height: 287px;
  background: #f0f5f5;
  border: 1px solid #a0dfc4;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px rgba(218, 218, 218, 0.3);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .txt {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.3px;
    width: 244px;
    height: 71px;
    color: #013c44;
    margin-top: 20px;
  }
}
