.HA_Right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;
}
.CD2_SCont_1121 {
  width: 41%;
  // height: 600px;
  height: 600px;
  border-radius: 30px;
  background: #d9edf1;
  border: 1px solid rgba(55, 129, 152, 0.3);
  
}
.Notes :hover{
 color:red
}
.Notes1 :hover{
  color:red
 }
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  /* background-color: #fff; */
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  width: 77.6%;
  overflow-x: auto;
}
.bsgpsK div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #046673;
  font-weight: bold;
  font-size: 16px;
}

.DCLCLB_Iptitle1 {
  margin-top: 15px;

  display: flex;
  align-items: center;
  height: 58px;

  background: #046673;
  border-radius: 12px;
  padding: 15px 10px;

  color: #fff;
  font-size: 18px;
  font-family: "VisueltRegular";
}
.DCLCLB_Iptitle11 {
  margin-top: 15px;

  display: flex;
  align-items: center;
  height: 45px;

  background: #046673;
  border-radius: 12px;
  padding: 15px 10px;
  width:60%;

  color: #fff;
  font-size: 14px;
  font-family: "VisueltRegular";
}
.DCLCLB_Iptitle2 {
  margin-top: 34px;
  padding: 16px;
  display: flex;
  align-items: center;
  // width: 51%;
  // height: 58px;
  min-height: 58px;
  max-height: 200px;
  background: #fff;
  border-radius: 20px;
  padding: 15px 10px;

  color: #013c44;
  font-size: 12px;
  font-family: "VisueltRegular";
}
.HA_Title_Content11{
  margin-top: 15px;

  display: flex;
  align-items: center;
  // height: 58px;

  // background: #fff;
  // border-radius: 20px;
  padding: 0px 10px;

  color: #013c44;
  font-size: 18px;
  font-family: "VisueltRegular";

}
.HA_Title_Content_button{
  margin-top: 20px;
  margin-left: 30%;
  display: flex;
  align-items: right;
  justify-content: right;
  height: 58px;

  background: #013c44;
  border-radius: 20px;
  padding: 15px 10px;

  color: #fff;
  font-size: 18px;
  font-family: "VisueltRegular";
}
.HA_Title_Content_button1{
  // margin-top: 20px;
  margin-left: 30%;
  display: flex;
  align-items: right;
  justify-content: right;
  // height: 58px;
 cursor: pointer;
  background: #c4f0dd;
  border-radius: 7px;
  padding: 5px 10px;

  color: #00839b;
  font-size: 13px;
  font-family: "VisueltRegular";
}
.row-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
  .top_cont_text {
    margin-left: 10px;
    .font_one {
      font-family: "GreycliffCFBold";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #00839b;
    }
    .font_two {
      font-family: "VisueltMedium";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;

      color: #00839b;

      opacity: 0.8;
    }
  }
}

.DCL_Container {
  width: 100%;
  justify-content: space-between;
}
.DCL_C_Row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.DCL_C_Row1 {
  display: flex;
  flex-direction: row;
  width: 146%;
}
.DCL_C_Column {
  display: flex;
  flex-direction: column;
}
.DCLC_Right {
  width: 59%;
  .DCLCR_Top {
    justify-content: space-between;
    align-items: center;
    //padding: 10px 0px;
    height: 70px;
    border-top: 0.5px solid rgba(151, 151, 151, 0.5);
    .DCLCRT_Left {
    }
    .DCLCRT_Right {
    }
  }
  .DCLCR_Bottom {
    height: 734px;
    border: 1px solid rgba(151, 151, 151, 0.5);
    border-radius: 35px;
    background-color: #000;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
      padding: 10px;
      background-color: #000;
      border-radius: 30px;
      overflow: hidden;
    }
  }
}

.DCL_BCStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  .BC-ImgC {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: #ff6e4e;
    border-radius: 10px;
    .BC_Img {
      width: 14px;
    }
  }
  .BC_Span {
    padding-left: 10px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: right;

    color: #00839b;
  }
}
.DCL_BCStyle1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  .BC-ImgC {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: #66b5c3;
    border-radius: 10px;
    .BC_Img {
      width: 14px;
    }
  }
  .BC_Span {
    padding-left: 10px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: right;

    color: #00839b;
  }
}
.DCL_Timing {
  background-color: gray;
  padding: 5px 20px;
  align-items: center;
  border-radius: 5px;
  .DCLT_Img {
    width: 30px;
  }
  .DCLT_Img2 {
    width: 35px;
  }
  .DCLT_Span {
    padding: 0px 20px 0px 5px;
  }
}
.DCLC_Left {
  width: 40%;
  // height: 720px;
  min-height: 500px;
  .DCLCL_Top {
    height: 368px;
    width: 100%;
    justify-content: space-between;
    gap:10px;
    .DCLCLT_Container {
      // width: 49%;
      width:100%;
      background: #ffffff;
      border: 1px solid rgba(102, 181, 195, 0.5);
      box-sizing: border-box;
      box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
      height: 460px;
      border-radius: 30px;
      align-items: center;
      padding: 14px 0px;

      .DCLCLTC_Header {
        width: calc(100% - 36px);
        background-color: #0a8c9d;
        font-family: Greycliff CF;
        color: #ffffff;
        padding: 13px;
        border-radius: 20px;
        margin: 0px 18px;
      }
      .DCLCLTC_ContentWraper {
        margin-top: 15px;
        width: 95%;
        overflow: auto;
        padding: 0px 18px;
        .DCLCLTC_Content {
          border-bottom: 0.5px solid #66b5c3;
          padding: 7px 0px;
        }
        .DCLCLTC_Content_L {
          padding: 7px 0px;
        }
        .DCLCLTC_Name {
          font-size: 18px;
          font-family: Greycliff CF;
          color: #013c44;
        }
        .DCLCLTC_SMS {
          font-size: 20px;
          font-family: "GreycliffCFBold";
          color: #0a8c9d;
          margin-left: 10px;
          cursor: pointer;
          &:hover {
            color: #013c44;
          }
        }
        .DCLCLTC_Value {
          font-size: 15px;
          font-family: "VisueltRegular";
          font-style: normal;
          font-weight: normal;
          line-height: 19px;

          color: #046673;

          opacity: 0.8;
        }
        .DS_Content {
          width: 100%;
          align-items: center;
          height: 55px;
        }
        .DSC_Div {
          width: 100%;
          justify-content: space-between;
          border-bottom: 0.5px solid #66b5c3;
          height: 100%;
          align-items: center;
          color: #00839b;
          margin-left: 15px;
        }
        .DS1_Img {
          width: 25px;
        }
        .DS_text {
          font-size: 12px;
        }
        .DS2_Img {
          width: 15.4px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .DCLCL_Top1 {
    height: 368px;
    width: 146%;
    justify-content: space-between;
    gap:10px;
    .DCLCLT_Container {
      // width: 49%;
      width:100%;
      background: #ffffff;
      border: 1px solid rgba(102, 181, 195, 0.5);
      box-sizing: border-box;
      box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
      height: 460px;
      border-radius: 30px;
      align-items: center;
      padding: 14px 0px;

      .DCLCLTC_Header {
        width: calc(100% - 36px);
        background-color: #0a8c9d;
        font-family: Greycliff CF;
        color: #ffffff;
        padding: 13px;
        border-radius: 20px;
        margin: 0px 18px;
      }
      .DCLCLTC_ContentWraper {
        margin-top: 15px;
        width: 95%;
        overflow: auto;
        padding: 0px 18px;
        .DCLCLTC_Content {
          border-bottom: 0.5px solid #66b5c3;
          padding: 7px 0px;
        }
        .DCLCLTC_Content_L {
          padding: 7px 0px;
        }
        .DCLCLTC_Name {
          font-size: 18px;
          font-family: Greycliff CF;
          color: #013c44;
        }
        .DCLCLTC_SMS {
          font-size: 20px;
          font-family: "GreycliffCFBold";
          color: #0a8c9d;
          margin-left: 10px;
          cursor: pointer;
          &:hover {
            color: #013c44;
          }
        }
        .DCLCLTC_Value {
          font-size: 15px;
          font-family: "VisueltRegular";
          font-style: normal;
          font-weight: normal;
          line-height: 19px;

          color: #046673;

          opacity: 0.8;
        }
        .DS_Content {
          width: 100%;
          align-items: center;
          height: 55px;
        }
        .DSC_Div {
          width: 100%;
          justify-content: space-between;
          border-bottom: 0.5px solid #66b5c3;
          height: 100%;
          align-items: center;
          color: #00839b;
          margin-left: 15px;
        }
        .DS1_Img {
          width: 25px;
        }
        .DS_text {
          font-size: 12px;
        }
        .DS2_Img {
          width: 15.4px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .DCLCL_Bottom {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(102, 181, 195, 0.5);
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    height: 460px;
    // margin-top: 30px;
    margin-left: 30px;
    border-radius: 30px;
    padding: 20px;
    margin-left: 0px;
    // margin-top: 25%;
    .DCLCLB_Text {
      color: #046673;
      font-family: Greycliff CF;
      font-weight: bold;
      font-size: 20px;
    }
    .DCLCLB_Iptitle {
      margin-top: 15px;

      display: flex;
      align-items: center;
      height: 58px;

      background: rgba(209, 232, 237, 0.3);
      border-radius: 20px;
      padding: 15px 10px;

      color: #013c44;
      font-size: 18px;
      font-family: "VisueltRegular";
    }
    .DCLCLB_Ipnotes {
      margin-top: 15px;
      border-radius: 20px;
      height: 240px;
      padding: 15px 10px;
      background: rgba(209, 232, 237, 0.3);
      color: #013c44;
      font-size: 18px;
      font-family: "VisueltRegular";
      overflow: auto;
    }
  }
  .DCLCL_Bottom1 {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(102, 181, 195, 0.5);
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    height: 460px;
    // margin-top: 30px;
    margin-left: 30px;
    border-radius: 30px;
    padding: 20px;
    margin-left: 0px;
    margin-top: 25%;
    .DCLCLB_Text {
      color: #046673;
      font-family: Greycliff CF;
      font-size: 20px;
    }
    .DCLCLB_Iptitle {
      margin-top: 15px;
  
      display: flex;
      align-items: center;
      height: 58px;
  
      background: rgba(209, 232, 237, 0.3);
      border-radius: 20px;
      padding: 15px 10px;
  
      color: #013c44;
      font-size: 18px;
      font-family: "VisueltRegular";
    }
    .DCLCLB_Ipnotes {
      margin-top: 15px;
      border-radius: 20px;
      height: 240px;
      padding: 15px 10px;
      background: rgba(209, 232, 237, 0.3);
      color: #013c44;
      font-size: 18px;
      font-family: "VisueltRegular";
      overflow: auto;
    }
  }
}

.CD_Container {
  width: 100%;
  background-color: #ffffff;
  height: auto;

  .CD_Subcontainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3%;
    // padding: 25px;
    // margin-left: -4.5%;
  }
  .CD_SCont_1 {
    width: 26%;
    height: 600px;
    border-radius: 30px;
    background: #d9edf1;
    border: 1px solid rgba(4, 102, 115, 0.3);
  }
  .CD_SCont_2 {
    width: 43%;
    border: 1px solid red;
    // height: 460px;
    border-radius: 30px;
    background: #eff1f7;
    border: 1px solid rgba(4, 102, 115, 0.3);
  }
  .CD2_SCont_1 {
    width: 26%;
    // height: 600px;
    height: 600px;
    border-radius: 30px;
    background: #d9edf1;
    border: 1px solid rgba(55, 129, 152, 0.3);
    
  }
  .CD2_SCont_2 {
    width: 43%;
    border: 1px solid red;
    height: 600px;
    border-radius: 30px;
    background: #d9edf1;
    border: 1px solid rgba(55, 129, 152, 0.3);
  }
  .HA_Title {
    color: #046673;
    font-family: Greycliff CF;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid #00839b;
    height: 65px;
    display: flex;
    align-items: flex-end;
  }
  .HA_Title112 {
    color: #046673;
    font-family: "GreycliffCFBold";
    font-size: 18px;
    // padding: 10px 20px;
    border-bottom: 1px solid #00839b;
    height: 65px;
    display: flex;
    align-items: flex-end;
    gap: 9px;
  }
  
  .HA_Content {
    height: 505px;
    width: 100%;
    overflow: auto;
  }
  .HA_Left {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    position: sticky;
    top: 1px;
    .HAL_Line {
      background: linear-gradient(180deg, #01b8da 0%, #046673 100%), #046673;
      width: 2px;
      height: 100%;
    }
  }
  .HAR_Space {
    height: 25px;
  }
  .HAR_Space1 {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .BC_ImgC {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      background: #66b5c3;
      border-radius: 10px;
      .BC_Img {
        width: 14px;
      }
    }
    span {
      padding-left: 20px;
      font-family: "GreycliffCFBold";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;

      color: #046673;
    }
  }
  .HASL_Read {
    height: 24px;
    width: 88px;
    background-color: #d9edf1;
    border-radius: 10px;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: CENTER;
    margin-top: 5px;
    cursor: pointer;
    span {
      font-family: "VisueltRegular";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      color: #046673;

      opacity: 0.8;
    }
    img {
      width: 11px;
      margin-left: 5px;
    }
  }
  .HAS_Middle {
    width: 6%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .HASM_Div {
    width: 0.5px;
    background-color: #66b5c3;
    height: 100%;
  }
  .HA_Right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
  }
  .HAs_Container_Wraper {
    width: 100%;
  }
  .HAs_Container {
    width: 100%;
    height: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
    background: #ffffff;
    border-radius: 20px;
    margin: 10px 0px;
  }
  .HAs_History {
    width: calc(100% - 5px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 10px;
    background: #dbede5;
    border-radius: 20px;
    margin: 10px 0px;
    // margin-left: 20px;
    .title {
      width: 240px;
      min-height: 40px;
      background: #00839b;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding-left: 22px;
      font-family: "VisueltRegular";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: #fff;
      margin: 10px 0px;
    }
    .value {
      width: 240px;
      min-height: 40px;
      background: #00839b;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 22px;
      margin-bottom: 10px;
      color: #046673;
      background-color: #fff;
      font-family: "VisueltRegular";
      .date {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;
        padding-right: 10px;
        font-family: "VisueltMedium";
        img {
          margin-right: 10px;
        }
      }
      span {
        padding-right: 10px;
      }
      .name {
        display: flex;
        width: 100%;
        align-items: center;
        // margin-bottom: 10px;
        padding-right: 10px;
        justify-content: space-between;
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 10px;
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "GreycliffCFBold";
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          width: 78.87px;
          min-height: 28.16px;
          text-align: center;
          background: #c4f0dd;
          border-radius: 7px;
          color: #00839b;
          cursor: pointer;
        }
      }
    }
  }
  .HASC_Dot {
    width: 15px;
    height: 15px;
    position: absolute;
    left: -28px;
    border-radius: 10px;
    background: #ffffff;
    border: 3px solid #37cc8c;
  }
  .HASC_Dot1 {
    width: 15px;
    height: 15px;
    position: relative;
    left: 2.8%;
    border-radius: 10px;
    background: #ffffff;
    border: 3px solid #37cc8c;
    margin-left: -36px;
    margin-top: -36px;
  }
  
  .HAS_Left {
    width: 47%;
  }
  .HASL_Content {
  }
  .HASLC_Name {
    font-size: 12px;
    font-family: "GreycliffCFBold";
    color: #046673;
  }
  .HASLC_Value {
    font-size: 12px;
    color: #046673;
    font-family: "VisueltRegular";
  }
  .HAS_Right {
    width: 47%;
    .PHP_Name {
      font-family: VisueltRegular !important;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;

      color: #046673;
    }
    .PHP_Value {
      font-family: "VisueltRegular";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      word-break: break-all;
      color: #046673;

      opacity: 0.8;
    }
  }
  .HASR_Content {
    padding: 4px;
    background: rgba(4, 169, 200, 0.1);
    border-radius: 10px;
    font-family: "VisueltRegular";
    color: #013c44;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    cursor: pointer;
    color: #013c44;
  }
  .HA_Cont_style {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .HA_Cont_style1 {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    height: 505px;
    // overflow-y: scroll;
    
  }
  .HA_Tab_Container {
    height: 45px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  .HA_Tab_Container_1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: Greycliff CF;
    align-items: center;
    height: 73px;
    //padding: 0px 30px;
    .MuiTabs-flexContainer {
      justify-content: space-between;
    }
  }
  .HA_Tab {
    font-size: 18px;
    line-height: 22px;
    color: #046673;
    font-family: "GreycliffCFBold";
  }
  .HAT_Select {
    color: #04a9c8;
    border-bottom: 3px solid #04a9c8;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .HA_Tab1 {
    font-size: 18px;
    line-height: 22px;
    color: #013c44;
    font-family: "GreycliffCFBold";
  }
  .HAT_Select1 {
    color: #013c44;
    border-bottom: 3px solid #04a9c8;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .PHP_Container {
    max-height: 400px;
    overflow: auto;
    padding: 20px;
    .Container-WithHeader {
      border: 1px solid #a0dfc4;
      border-radius: 20px;
      margin-top: 33px;
      padding: 19px;
      position: relative;
      display: flex;
      flex-direction: column;
      .title {
        position: absolute;
        position: absolute;
        top: -20px;
        background-color: #eff1f7;
        padding: 10px;
        font-family: "GreycliffCFBold";
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: -0.3px;
        color: #00839b;
      }
      .rowdata {
        display: flex;
        border-bottom: 0.5px solid #00839b;
        .PHP_Suncontainer {
          width: 33.33%;
          border-right: 1px solid #00839b;
          padding: 0px 10px;
          margin: 10px 0px;
        }
        .PHP_Suncontainer:nth-last-child(1) {
          border-right: none;
        }
        .centeralign {
          display: flex;
          justify-content: center;
          // align-items: center;
        }
      }
      .rowdata:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }
  .PHP_Suncontainer {
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .PHP_Suncontainer1 {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .PHP_Name {
    font-size: 17px;
    line-height: 20px;
    color: #046673;
    font-family: "VisueltRegular";
    margin-bottom: 5px;
  }
  .PHP_Value {
    font-size: 15px;
    line-height: 19px;
    color: #046673;
    opacity: 0.8;
    font-family: "VisueltRegular";
  }
  .CCN_Search {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #00839b;
    padding: 10px;
    min-height: 50px;
    margin-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    .headersearchtxt {
      background: #00839b;
      border: 1px solid #00839b;
      border-radius: 10px;
      width: 80%;
      height: 50px;
      padding-left: 10px;
      margin-right: 20px;
      color: #fff;
    }
    .headersearchtxt::placeholder {
      color: #fff;
      padding-left: 10px;
    }
  }
  .CCNS_LI {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
    }
  }
  .CCNS_Text {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    color: #ffffff;
    width: 80%;
    font-family: "VisueltRegular";
  }
  .CCNS_Text1 {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    color: #ffffff;
    width: 90%;
    font-family: "VisueltRegular";
  }
  .CCNS_Textblue {
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    color: #046673;
    width: 90%;
    font-family: "VisueltRegular";
    padding-left: 5px;
  }
  .CCN_Doc {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 10px;
    height: 50px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 10px;
    border: 1px solid #a0dfc4;
    .headersearchtxt {
      background: #fff;
      border: 0px solid #fff;
      width: 100%;
      height: 46px;
      padding-left: 10px;
      margin-right: 20px;
      color: #046673;
    }
    .headersearchtxt::placeholder {
      color: #046673;
      padding-left: 10px;
    }
  }
  .CCN_Doct {
    width: 100%;

    background-color: #ffffff;
    padding: 10px;
    //height: 100px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #a0dfc4;
  }
  .ccn_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ccn_wrap_one {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
  }
  .CCND_LI {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
    }
  }
  .CCND_Text {
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    color: #046673;
    width: 80%;
    font-family: "VisueltRegular";
  }
  .CCN_Doc1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    padding: 10px;
    min-height: 50px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #a0dfc4;
    justify-content: space-between;
  }
  .CCND1_Text {
    display: flex;
    align-items: center;
    letter-spacing: -0.333333px;
    font-family: "VisueltRegular";
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #00839b;
    padding-left: 15px;
  }
  .change_status_text {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding-left: 18px;
    line-height: 193.8%;
    color: #013c44;
  }
  .change_status_foucs {
    background: #c4f0dd;
  }
  .CCND1_Text1 {
    font-size: 18px;
    line-height: 22px;
    color: #013c44;
    display: flex;
    align-items: center;
    font-family: "VisueltRegular";
    text-transform: capitalize;
  }
  .CCND1_Rev {
    background: #c4f0dd;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #00839b;
    font-family: "GreycliffCFBold";
    cursor: pointer;
    img {
      width: 20px;
    }
  }
  .CCND1_Down {
    //background: #c4f0dd;
    border-radius: 10px;
    padding: 5px 5px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #00839b;
    font-family: "GreycliffCFBold";
    cursor: pointer;
    img {
      width: 16px;
    }
  }
  .CCN_Doc_Sel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #c4f0dd;
    padding: 10px;
    height: 50px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #a0dfc4;
    justify-content: space-between;
  }
  .CCN_Description {
    background: #00839b;
    box-shadow: 0px 0px 20px rgba(191, 197, 245, 0.15);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    max-height: 150px;
    overflow: auto;
    div {
      font-size: 14px;
      line-height: 173.3%;
      letter-spacing: -0.333333px;
      color: #ffffff;
      font-family: "VisueltRegular";
      cursor: pointer;
    }
  }
  .CCN_Editbtn {
    position: absolute;
    bottom: 30px;
    right: 30px;
    padding: 13px 20px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    background: #00839b;
    border-radius: 10px;
    font-family: "GreycliffCFBold";
    cursor: pointer;
  }
  .CCN_Doc_Des_extent {
    height: 193px;
    width: 100%;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #a0dfc4;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(191, 197, 245, 0.15);
    border-radius: 10px;
    padding-left: 18px;
    padding-top: 14px;
    .timedropdown {
      .dropdown {
        .ddown {
          width: 165px;
          min-height: 50px !important;
        }
        .popUp {
          min-width: 165px !important;
          max-height: 200px !important;
          overflow: auto;
          top: 50px;
          bottom: 0px;
          div {
            font-size: 15px !important;
          }
        }
      }
    }
  }
  .disable_drop {
    pointer-events: none;
    cursor: not-allowed;
  }

  .timedropdown {
    .title {
      font-family: "GreycliffCFBold";
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;

      color: #013c44;
      span {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
      }
    }
    .dropdown {
      margin-top: 5px;
      margin-bottom: 18px;

      .ddown {
        width: 165px;
        height: 50px;
        display: flex;
        position: relative;
        align-items: center;
        background: rgba(209, 232, 237, 0.3);
        border-radius: 20px;
        span {
          margin-left: 18px;
          font-family: "VisueltRegular";
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #013c44;
        }
        img {
          width: 8.38px;
          height: 13.43px;

          color: #00839b;

          position: absolute;
          right: 20px;
        }
        .popUp {
          position: absolute;
          bottom: -330px;
          left: 0px;

          display: flex;
          align-items: flex-start;
          z-index: 99;
          flex-direction: column;
          min-width: 358px;
          height: 488px;
          background: #eff8fa;
          border: 1px solid #64d1e4;
          box-sizing: border-box;
          border-radius: 20px;
          max-height: 320px;
          overflow: auto;
          flex-direction: column;
          div {
            font-family: "VisueltRegular";
            color: #013c44;
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 208.2%;
            padding-left: 39px;
            cursor: pointer;
            width: 100%;
          }
          :hover {
            background: #c4f0dd;
          }
        }
        .popUp2 {
          position: absolute;
          bottom: -195px;
          left: 0px;

          display: flex;
          align-items: flex-start;
          z-index: 99;
          flex-direction: column;
          min-width: 358px;
          background: #eff8fa;
          border: 1px solid #64d1e4;
          box-sizing: border-box;
          border-radius: 20px;
          max-height: 207px;
          flex-direction: column;
          padding: 10px 0px;
          div {
            font-family: "VisueltRegular";
            color: #013c44;
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 53px;
            padding-left: 39px;
            cursor: pointer;
            width: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .CCN_Doc_Des {
    height: 127px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #a0dfc4;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(191, 197, 245, 0.15);
    border-radius: 10px;
    padding-top: 14px;
    margin-top: 10px;
    cursor: pointer;
  }
  .CCN_Doc_DesFollow {
    width: 100%;
    padding-bottom: 20px;
    max-height: 262px;
    background: #ffffff;
    border: 1px solid #a0dfc4;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(191, 197, 245, 0.15);
    border-radius: 10px;
    padding-top: 14px;
    padding-left: 18px;
    margin-top: 10px;
    .mySelect {
      margin: 0;
      width: 74px;
      margin-right: 10px;
    }
    .CCNS_Submit {
      margin-left: 0px;
    }
    .CCNS_submit_disable_follow_up {
      margin-left: 0px;
    }
  }

  .CCNDS_Head {
    font-size: 15px;
    line-height: 118.5%;
    color: #046673;
    font-family: "VisueltRegular";
  }
  .CCNDS_Text {
    font-size: 13px;
    line-height: 118.5%;
    color: #046673;
    font-family: "VisueltRegular";
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .CCNDS_Btncont {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
  .CCNS_Btn {
    width: 30%;
    background: #deebee;
    opacity: 0.5;
    border: 1px solid #00839b;
    box-sizing: border-box;
    border-radius: 10px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "GreycliffCFBold";
    img {
      width: 20px;
    }
  }
  .CCNS_Submit {
    margin-top: 17px;
    margin-left: 14px;
    cursor: pointer;
    span {
      background: #00839b;
      border-radius: 10px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      padding: 10px 20px;
      font-family: "GreycliffCFBold";
    }
  }
  .CCNS_submit_disable {
    margin-top: 17px;
    margin-left: 14px;
    cursor: not-allowed;
    pointer-events: none;
    span {
      background-color: #cccccc;
      border-radius: 10px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      padding: 10px 20px;
      font-family: "GreycliffCFBold";
    }
  }
  .CCNS_submit_disable_follow_up {
    width: "88px";
    margin-top: 17px;
    margin-left: 14px;
    background-color: #dfeeea;
    cursor: not-allowed;
    pointer-events: none;
    span {
      cursor: not-allowed;
      pointer-events: none;
      border-radius: 10px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #c0c0c0;
      padding: 10px 20px;
      font-family: "GreycliffCFBold";
    }
  }
  .CCNS_Submit_follow_up {
    margin-top: 26px;
    margin-left: 14px;
    span {
      background: #00839b;
      border-radius: 10px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      padding: 10px 20px;
      font-family: "GreycliffCFBold";
    }
  }
}
.Sidemenu {
  height: 100%;
}
@media (max-width: 1280px) {
  // .DCLC_Right {
  //   width: 59%;
  // }
  .DCLC_Left {
    width: 100%;
    margin-top: 30px;
    .DCLCL_Bottom {
      height: 337px;
      .DCLCLB_Ipnotes {
        max-height: 58%;
      }
    }
  }
  .DCL_Container {
    flex-direction: column;
  }
  .CD_Container {
    .HA_Left {
      width: 15%;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      position: sticky;
      top: 1px;
      .HAL_Line {
        background: linear-gradient(180deg, #01b8da 0%, #046673 100%), #046673;
        width: 2px;
        height: 100%;
      }
    }
    .CD_Subcontainer {
      flex-direction: row;
      flex-wrap: wrap;
      .CD_SCont_1 {
        width: 49%;
      }
      .CD_SCont_2 {
        width: 100%;
        margin-top: 30px;
      }
      .CD2_SCont_2 {
        width: 100%;
      }
      .CD2_SCont_1 {
        width: 49%;
        margin-top: 30px;
      }
    }
  }
}

.PHP_MD_CONTAINER {
  margin: 20px 0;
  padding: 0 0 15px 0;
}

.PHP_MD_CONTAINER_BORDER:not(:last-child) {
  margin: 20px 0;
  padding: 0 0 15px 0;
  border-bottom: 0.5px solid #00839b;
}

.PHP_MD_HEADING {
  font-family: "GreycliffCFBold";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #013c44;
  margin-bottom: 20px;
  word-break: break-all;
}

.PHP_MD_TXT {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  word-break: break-all;
  color: #013c44;
  .Medical {
    margin-bottom: 10px;
    .mtitle {
      color: #013c44;
      font-family: "GreycliffCFBold";
      margin-bottom: 5px;
    }
    .mvalue {
    }
  }
}

.PHP_OB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PHP_MD_CONTAINER {
  display: block;
  margin: auto;
  width: 514px;
  padding: 20px;
}

.PHP_MD_CONTAINER_BORDER:not(:last-child) {
  display: block;
  margin: auto;
  width: 514px;
  padding: 20px;
  border-bottom: 0.5px solid #00839b;
}

.PHP_OB_TXT {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.303051px;

  color: #013c44;
}

.PHP_OB_BTN {
  width: 118.87px;
  height: 32.17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #04a9c8;
  border-radius: 10.1017px;
}

.PHP_OB_BTN_TXT {
  display: block;
  margin: auto;
  font-family: "GreycliffCFRegular";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
}

.PHP_OB_DIV {
  width: 1px;
  height: 17px;

  background: #c7e0e9;
  border-radius: 50.5085px;
}

.BP_CONTAINER {
  width: 441px;
  // height: 331px;
  padding: 20px;
  background: #eff1f7;
  border: 1px solid rgba(4, 102, 115, 0.3);
  box-sizing: border-box;
  border-radius: 30px;
}

.BP_HEADING1 {
  font-family: "GreycliffCFBold";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.303051px;
  margin-bottom: 35px;
  color: #013c44;
}

.BR_HEADING2 {
  font-family: "GreycliffCFBold";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  margin-bottom: 15px;
  color: #013c44;
}

.BP_SUB_DIV {
  width: 386.17px;
  height: 51.25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1.19402px solid #a0dfc4;
  padding: 0 25px;
  box-sizing: border-box;
  border-radius: 10.1017px;
  margin-bottom: 15px;
}

.BP_SUB_DIV_TXT {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.303051px;

  color: #00839b;
}
.disabledType {
  pointer-events: none;
  opacity: 0.4;
}
.zoomiframe {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fff;
  div {
    width: 100px;
    height: 100px;
    background-color: #013c44;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    span {
      color: #ffffff;
      font-size: 13px;
      font-family: "GreycliffCFBold";
    }
  }
}
.chaperone_lab_container {
  width: 364px;
  height: 135px;
  background: #ffffff;
  border: 0.971154px solid #a0dfc4;
  box-sizing: border-box;
  border-radius: 9.71154px;
  margin-top: 24px;
}
.CCN_Doc_two {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  height: 50px;
  margin-top: 10px;
}
.btn_chap_one {
  margin-top: 23px;
  margin-bottom: 25px;
  border-radius: 10px;
  width: 159px;
  height: 40px;
  background: #00839b;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 5px 10px;
    font-family: "GreycliffCFBold";
    color: #ffffff;
  }
}
.LocalPrescription {
  background-color: #fff;
  border: 0.971154px solid #a0dfc4;
  box-sizing: border-box;
  border-radius: 9.71154px;
  margin: 30px;
  padding: 30px 20px 15px 20px;
  .ContOne {
    display: flex;
    img {
      margin-right: 20px;
    }
  }
  .ContTwo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .ButtonLocal {
      font-family: "VisueltRegular";
      background: #00839b;
      border-radius: 10px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      width: 48%;
      text-align: center;
      font-size: 13px;
      padding: 10px;
      cursor: pointer;
    }
    .disable_rev {
      border-radius: 10px;
      font-family: "VisueltRegular";
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px;
      width: 48%;
      text-align: center;
      font-size: 13px;
      padding: 10px;
      background-color: #dfeeea;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
.HA_Icon {
  color: #046673;
  font-family: "GreycliffCFBold";
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  // border-bottom: 1px solid #00839b;
  height: 65px;
  display: flex;
  align-items: flex-end;
}
.addquery {
  width: 931px;
  height: 463px;
  background: rgba(209, 232, 237, 0.3);
  border: 1px solid rgba(4, 102, 115, 0.3);
  box-sizing: border-box;
  border-radius: 30px;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  color: #046673;
  padding-left: 90px;
  padding-top: 48px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.addquery:focus-visible {
  outline: none !important;
  outline-offset: 0px !important;
}
.addquery::placeholder {
  color: #046673;

  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}
.int_btnn {
  margin-top: 10px;
  border-radius: 10px;
  width: 244px;
  height: 40px;
  background: #00839b;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    // padding: 5px 10px;
    font-family: "GreycliffCFBold";
    color: #ffffff;
  }
}
.int_btnn_dis {
  margin-top: 10px;
  border-radius: 10px;
  width: 244px;
  height: 40px;
  background: #c9c9c9;
  cursor: pointer;
  pointer-events: none;
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "GreycliffCFBold";
    color: #4e4e4e;
  }
}
.clickrefresh {
  display: inline;
  margin-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #046673;
}
.MuiDialog-container {
  input[type="text"]:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
  input[type="text"] {
    outline: none !important;
    outline-offset: 0px !important;
  }
}
.pdfiframe {
  height: 75vh;
  width: 96%;
  margin: 25px;
  border: 0px;
  display: block;
}

.reff_listing {
  width: 100%;
  flex-direction: row;
  min-height: 50px;
  margin-top: 10px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: #00839b;
  .review_btn {
    background: #c4f0dd;
    border-radius: 10px;
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00839b;
    cursor: pointer;
    margin-left: 5px;
    padding: 10px 15px;
  }
}
