.ConfirmAvailability{
    width: 423px;
    height: 298px;

    background: #F0F5F5;
    box-shadow: 0px 0px 50px rgba(38, 43, 82, 0.05);
    border-radius: 35px;
    padding: 60px 30px;

    .heading{
        font-family: 'GreycliffCFBold';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;

        color: #013C44;
    }

    .buttonContaoner{
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        .btn{
            width: 142px;
            height: 62px;
            background: #08B4D4;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            font-size: normal;
            font-family: 'GreycliffCFBold';
            font-size: 30px;
            cursor: pointer;

            &:hover{
                background-color: #00839B;
            }

        }
    }
}

.SuccessMsg{
        width: 423px;
        height: 298px;

        background: #F0F5F5;
        box-shadow: 0px 0px 50px rgba(38, 43, 82, 0.05);
        border-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        

        .MsgContainer{
            
            display: block;
            font-family: 'GreycliffCFBold';
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.3px;

            color: #013C44;

            img{
                display: block;
                margin: auto;
                margin-bottom: 20px;
            }
        }

        .ErrorMsg{
            
            display: block;
            font-family: 'GreycliffCFBold';
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.3px;

            color: red;

            img{
                display: block;
                margin: auto;
                margin-bottom: 20px;
            }
        }
}
