.approve_btn {
    margin-top: 43px;
    border-radius: 10px;
    width: 384px;
    height: 79px;
    background: #00839B;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      display: flex;
      padding-top: 22px;
      justify-content: center;
      align-items: center;
      text-align: center;
      // padding: 5px 10px;
        font-family: "GreycliffCFBold";
        color:#FFFFFF;
    }
  }
  .query_btn {
    margin-top: 43px;
    margin-left: 51px;
    border-radius: 10px;
    width: 384px;
    height: 79px;
    background: #FF6E4E;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      display: flex;
      padding-top: 22px;
      justify-content: center;
      align-items: center;
      text-align: center;
      // padding: 5px 10px;
        font-family: "GreycliffCFBold";
        color:#FFFFFF;
    }
  }