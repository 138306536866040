@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/* .icon {
font-family: 'Material Icons', serif;
font-style: normal;
display: inline-block;
vertical-align: middle;
line-height: 1;
text-transform: none;
letter-spacing: normal;
word-wrap: normal;
white-space: nowrap;
direction: ltr;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
-moz-osx-font-smoothing: grayscale;
font-feature-settings: 'liga';
} */
/* GENERAL */
* {
    /* box-sizing: border-box; */
}
body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    position: relative;
}
.container {
    display: flex;
    flex-direction: column;
}
.header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.container-border {
    background: #ffffff;
    border: 1px solid rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    box-shadow: -1px 19px 32px rgba(8, 180, 212, 0.15);
    border-radius: 30px;
    position: relative;
}
.month-header {
    margin-left: 38px;
    font-family: "GreycliffCFBold";

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #046673;
}
.month-nav {
    margin-right: 17px;
    display: inline-flex;
}
.icon {
    border: 1px solid #a0dfc4;
    box-sizing: border-box;
    border-radius: 7px;
    margin: 0 12px;
    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: CENTER;
}
.icon-button {
    color: #013c44;
    padding: 3px;
}
.icon-button-left {
    color: #013c44;
    padding: 3px;
    margin-left: 5px;
}
.header-border {
    height: 0.5px;
    background-color: #00839b;
    margin-top: 28px;
    margin-bottom: 40px;
}
.days-class {
    flex-grow: 1;
}
.days-header {
    color: yellow;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    font-family: "GreycliffCFBold";

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #046673;
}
.days-render {
    flex-grow: 1;
    max-width: 100%;
}

.disabled {
    color: red;
    pointer-events: none;
}

.days-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.column-days {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}
.container .column-days .disabled {
    color: lightgrey;
    pointer-events: none;
    font-family: "VisueltRegular";
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 254.2%;
    /* or 51px */
    text-align: center;
    letter-spacing: 0.015em;
}
.marker {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-top: 30px;
    background: #ff6e4e;
    margin-bottom: 4px;
}
.current-day {
    background-color: #046673;
    color: white;
}
.overlay {
    z-index: 99;
    background-color: #013c4473;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupoverlay {
    background: #f3fafc;
    border: 1px solid #66b5c3;
    box-sizing: border-box;
    box-shadow: -7px 6px 7px rgba(6, 99, 116, 0.05);
    border-radius: 30px;
    width: 60%;
    height: 75%;
    position: relative;
}
.popuptone {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 0%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.015em;
    margin-right: 5px;
    color: #046673;
}
.popupttwo {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 0%;
    /* or 0px */

    display: flex;
    align-items: center;
    letter-spacing: 0.015em;

    color: #046673;
}
.popupcontent {
    margin-bottom: 7px;
    padding: 0px 17px;
    position: relative;

}
.popupcontainer {
    max-height: 80%;
    overflow-x: auto;
    padding-top: 10px;
}
.popupcone {
    font-family: "VisueltRegular";

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 106%;
    /* identical to box height, or 14px */

    letter-spacing: -0.3px;
    margin-left: 17px;
    color: #046673;
}
.popupctwo {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 106%;
    /* identical to box height, or 11px */

    letter-spacing: -0.3px;

    color: #046673;
}
.CalClose{
    position: absolute;
    right: -25px;
    top: -25px;
}
.popupdot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #ff6e4e;
    margin-right: 10px;
    position: absolute;
    top: 10px;
}
.popupdate {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 106%;
    /* identical to box height, or 11px */
    margin-left: 17px;
    margin-top: 5px;
    letter-spacing: -0.3px;

    color: #046673;
}
.popuptitle {
    height: 50px;
    border-bottom: 0.5px solid #046673;
    margin: 0px 17px;
    display: flex;
}
.container .column-days .number {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 254.2%;
    /* or 51px */
    padding: 5px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #046673;
}
/* .container .column-days .number:hover {
        background:#046673;
        transition: 0.5s ease-out;
        border-radius: 10%;
        color:white;
        } */
.container .column-days .selecteddate {
    background: #046673;
    transition: 0.5s ease-out;
    border-radius: 10%;
    color: white;
}

/* .cointainer .number{
          
        } */
/* .container .body .column-days:hover .number, .container .body .selected .number   {
            visibility:visible;
            } */

/* GRID */
.row {
    margin-top: 21px;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.row-middle {
    align-items: center;
}
.column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.calendar .body .column {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}
